/*
 * @Author: your name
 * @Date: 2022-03-01 19:07:13
 * @LastEditTime: 2023-11-21 20:29:26
 * @LastEditors: cuihaopeng cuihaopeng@slodon.cn
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /weichao_pc/src/utils/config.js
 */

/**
 *  项目的配置文件
 */
// export const apiUrl = 'https://weichaopc.slodon.cn/';//接口请求地址
// export const apiUrl = 'https://www.tinytide.cn/';//接口请求地址
export const apiUrl = window.location.origin+'/';//接口请求地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
// export const chatUrl = 'wss://weichaoim.slodon.cn';//客服地址
export const chatUrl = 'wss://im.tinytide.cn';//客服地址
export const mUrl = ''//移动端网页地址
export const title = '微潮商城 政企自助采购平台';//浏览器顶部title
export const gdKey = '';//高德web-js key
export const statShowDebug = false;//是否开启统计的调试

// ** copyright *** slodon *** version-v3.6 *** date-2021-12-17 ***主版本v3.6



